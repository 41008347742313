import React, { useState, useRef, useEffect } from 'react';

import Init from '../../../Init/Init';
import UtilityFunction from '../../../Util/Util';
import ContactInfo from '../../../Data/Contact';

import './CheckoutSection.scss';

import { Modal, Image, Spinner } from 'react-bootstrap';
import AddressSection from '../../PagesComponent/AddressSection/AddressSection';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import ShippingForm from '../../PagesComponent/CartPage/ShippingForm/ShippingForm';
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import PaymentForm from '../../PagesComponent/CartPage/PaymentForm/PaymentForm';
import PaymentDirectionForm from '../../PagesComponent/CartPage/PaymentDirectionForm/PaymentDirectionForm';
import CustomDesignForm from '../../PagesComponent/CartPage/CustomDesignForm/CustomDesignForm';
import { connect } from 'react-redux';

const { numberFormatting } = UtilityFunction;

const mapStateToProps = (state) => {
    return {
        designAttachment: state.shop.designAttachment
    }
};

const CheckoutSection = (props) => {
    const { FontAwesomeIcon, config } = Init; 
    const { bankAccount } = ContactInfo();

    const [ showQR, setShowQR ] = useState(false);
    const openQR = () => setShowQR(false);
    const closeQR = () => setShowQR(false);

    const [ onProcess, setOnProcess ] = useState(false);

    const stateEnum = {
        contact: 0,
        design: 1,
        shipping: 2,
        payment: 3,
        paymentDirection: 4,
    }
    const [ formState, setFormState ] = useState(0);
    const checkoutFormData = useRef({
        customerName: "",
        phoneNo: "",
        provinceId: "",
        regencyId: "",
        districtId: "",
        villageId: "",
        postalCode: "",
        address: "",

        designAttachment: null,

        courierType: "", // JNE or Cargo
        usePaymentGateway: "",
        recipientAccountId: 0,
        transactionNotes: "",

        transactionItems: {}
    });

    const contactData = {
        firstName: {
            label: "Nama Lengkap",
            ref: useRef(''),
            value: '',
            width: 6, // x < 12
            type: "text",
            required: true,
            placeholder: ''
        },
        lastName: {
            label: "Nama Brand",
            ref: useRef(''),
            value: '',
            width: 6, // x < 12
            type: "text",
            required: true,
            placeholder: ''
        },
        phone: {
            label: "Phone Number/WA",
            ref: useRef(''),
            value: '',
            width: 12, // x < 12
            type: "text",
            required: true,
            placeholder: 'No. Telp',
            prefix: '+62'
        },
    };

    const shippingData = {
        // email: {
        //     label: "",
        //     ref: useRef(''),
        //     value: '',
        //     width: 12, // x < 12
        //     type: "text",
        //     required: true,
        //     placeholder: 'Email'
        // },
        province: {
            label: "Provinsi",
            ref: useRef(0),
            value: 0,
            width: 6, // x < 12
            type: "select",
            required: true,
            options: [
                {'value': 0, 'label': 'Pilih Provinsi'},
                {'value': 1, 'label': 'DKI Jakarta'},
                {'value': 2, 'label': 'Bekasi'},
                {'value': 3, 'label': 'Depok'},
                {'value': 4, 'label': 'Bandung'}
            ],
        },
        regency: {
            label: "Kota",
            ref: useRef(0),
            value: 0,
            width: 6, // x < 12
            type: "select",
            required: true,
            options: [
                {'value': 0, 'label': 'Pilih Kota/Kabupaten'},
                {'value': 1, 'label': 'Jakarta Barat'},
                {'value': 2, 'label': 'Jakarta Utara'},
                {'value': 3, 'label': 'Jakarta Timur'},
                {'value': 4, 'label': 'Jakarta Pusat'},
                {'value': 5, 'label': 'Jakarta Selatan'}
            ]
        },
        district: {
            label: "Kecamatan",
            ref: useRef(0),
            value: 0,
            width: 6, // x < 12
            type: "select",
            required: true,
            options: [
                {'value': 0, 'label': 'Pilih Kecamatan'},
                {'value': 1, 'label': 'Kalideres'},
                {'value': 2, 'label': 'Tanjung Duren'},
                {'value': 3, 'label': 'Pluit'},
                {'value': 4, 'label': 'Sunter'}
            ]
        },
        village: {
            label: "Kelurahan",
            ref: useRef(0),
            value: 0,
            width: 6, // x < 12
            type: "select",
            required: true,
            options: [
                {'value': 0, 'label': 'Pilih Kelurahan'},
                {'value': 1, 'label': 'Pegadungan'},
                {'value': 2, 'label': 'Cengkareng'},
                {'value': 3, 'label': 'Penjaringan'},
                {'value': 4, 'label': 'Pluit'}
            ]
        },
        postalCode: {
            label: "Postal Code",
            ref: useRef(''),
            value: '',
            width: 6, // x < 12
            type: "text",
            required: true,
            placeholder: ''
        },
        streetAddress: {
            label: "Alamat Lengkap",
            ref: useRef(''),
            value: '',
            width: 12, // x < 12
            type: "text",
            required: true,
            placeholder: ''
        },
        famousLandmark: {
            label: "Famous Landmark",
            ref: useRef(''),
            value: '',
            width: 12, // x < 12
            type: "text",
            required: false,
            placeholder: ''
        },
    };

    const chosenAddress = useRef();

    const { 
        iconFolder, 
        // imageFolder 
    } = config;

    const checkFormState = (checkFrom) => {
        return formState >= checkFrom;
    }

    const updateDesign = (designAttachment) => {
        checkoutFormData.current = {
            ...checkoutFormData.current,
            designAttachment
        }
    }
    const selectExpedition = (courierType) => {
        checkoutFormData.current = {
            ...checkoutFormData.current,
            courierType
        };
    }
    const selectPaymentMethod = (usePaymentGateway) => {
        checkoutFormData.current = {
            ...checkoutFormData.current,
            usePaymentGateway
        }
    };
    const selectPaymentRecipient = (recipientAccountId) => {
        checkoutFormData.current = {
            ...checkoutFormData.current,
            recipientAccountId
        }
    };

    const paymentInfoSave = () => {
        checkoutFormData.current = {
            ...checkoutFormData.current,
            designAttachment: props.designAttachment,
            customerName: `${contactData.firstName.ref.current.value} [${contactData.lastName.ref.current.value}]`,
            phoneNo: `${ contactData.phone.ref.current.value }`,
            provinceId: `${ shippingData.province.ref.current.value }`,
            regencyId: `${ shippingData.regency.ref.current.value }`,
            districtId: `${ shippingData.district.ref.current.value }`,
            villageId: `${ shippingData.village.ref.current.value }`,
            postalCode: `${ shippingData.postalCode.ref.current.value }`,
            address: `${ shippingData.streetAddress.ref.current.value } ${ shippingData.famousLandmark.ref.current.value ? `(${ shippingData.famousLandmark.ref.current.value })` : "" }`,
        };

        // setFormState(stateEnum.design);
        setFormState(stateEnum.shipping);
    };
    
    const designSave = () => {
        setFormState(stateEnum.shipping);
    };

    const shippingInfoSave = () => {
        if( checkoutFormData.current.courierType !== "" ) {
            setFormState(stateEnum.payment);
        }
    };

    const paymentMethodSave = () => {
        if( checkoutFormData.current.usePaymentGateway !== null ) {
            if( checkoutFormData.current.usePaymentGateway ) {
                checkout();
            } else {
                setFormState(stateEnum.paymentDirection);
            }
        }
    };

    const checkout = () => {
        if(!props.onProcess) {
            props.checkout(checkoutFormData.current);
        }
        // setOnProcess(true);
    };

    return (
        <>
            <Modal show={ showQR } onHide={ closeQR }>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Transfer to { bankAccount.bankAccountName }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image fluid={true} src={ bankAccount.bankAccountQr } />
                </Modal.Body>
            </Modal>
            <div id={props.id} className="checkout-section">
                <h2 className='checkout-section-title'>
                    Checkout
                </h2>
                <div className="checkout-detail">
                    {/* 
                        1. Steps
                        2. Changing Component
                            - Shipping Form (
                                First Name, 
                                Last Name, 
                                Phone No, 
                                Address, 
                                Province, 
                                Regency, 
                                District, 
                                Village, 
                                Postal Code, 
                                Famous Landmark
                            )
                            - Shipping Options (
                                JNE - Free, 
                                Cargo - 48000
                            )
                            - Payment Options (Xendit / Manual)
                    */}
                    <div className="steps">
                        <div className={`step-item ${ checkFormState(stateEnum.contact) ? 'active' : '' }`}>
                            Shipping
                        </div>
                        <hr />
                        <div className={`step-icon ${ checkFormState(stateEnum.shipping) ? 'active' : '' }`}>
                            <FontAwesomeIcon icon={['fas', 'check-circle']} />
                        </div>
                        <hr />
                        <div className={`step-item ${ checkFormState(stateEnum.shipping) ? 'active' : '' }`}>
                            Delivery
                        </div>
                        <hr />
                        <div className={`step-icon ${ checkFormState(stateEnum.payment) ? 'active' : '' }`}>
                            <FontAwesomeIcon icon={['fas', 'check-circle']} />
                        </div>
                        <hr />
                        <div className={`step-item ${ checkFormState(stateEnum.payment) ? 'active' : '' }`}>
                            Payment
                        </div>
                    </div>
                    <div className="step-component">
                        {/* 
                            1. Shipping Form
                            2. Shipping Options
                            3. Payment Options
                        */}
                        {
                            formState === stateEnum.contact ? (
                                <>
                                    <AddressSection 
                                        useTitle={true} 
                                        contactData={contactData} 
                                        shippingData={shippingData}
                                        chosenAddress={chosenAddress} 
                                        className="step-address-list"
                                    />
                                    {
                                        props.allowToProceed && (
                                            <PrimaryButton 
                                                onClick={ () => paymentInfoSave() }
                                                customClass="step-address-button"
                                            >
                                                {props.onProcess ? <Spinner animation='border' /> : 'Continue'}
                                            </PrimaryButton>
                                        )
                                    }
                                </>
                            ) : ""
                        }
                        {/* {
                            formState === stateEnum.design ? (
                                <>
                                    <CustomDesignForm attachment={checkoutFormData.current.designAttachment} updateAttachment={ (value) => updateDesign(value) } />
                                    {
                                        props.allowToProceed && (
                                            <PrimaryButton 
                                                onClick={ () => designSave() }
                                                customClass="step-address-button"
                                            >
                                                {props.onProcess ? <Spinner animation='border' /> : 'Continue'}
                                            </PrimaryButton>
                                        )
                                    }
                                    <SecondaryButton 
                                        onClick={ () => setFormState(stateEnum.contact) }
                                        customClass="step-address-back-button"
                                    >
                                        Back
                                    </SecondaryButton>
                                </>
                            ) : ""
                        } */}
                        {
                            formState === stateEnum.shipping ? (
                                <>
                                    <ShippingForm 
                                        formData={ checkoutFormData.current }
                                        chosenShipping={ checkoutFormData.current.courierType }
                                        onChange={ (value) => selectExpedition(value) }
                                        updateShippingFee={ (fee) => props.updateShippingFee(fee) }
                                    />
                                    {
                                        props.allowToProceed && (
                                            <PrimaryButton 
                                                onClick={ () => shippingInfoSave() }
                                                customClass="step-address-button"
                                            >
                                                {props.onProcess ? <Spinner animation='border' /> : 'Continue'}
                                            </PrimaryButton>
                                        )
                                    }
                                    <SecondaryButton 
                                        onClick={ () => setFormState(stateEnum.contact) }
                                        customClass="step-address-back-button"
                                    >
                                        Back
                                    </SecondaryButton>
                                </>
                            ) : ""
                        }
                        {
                            formState === stateEnum.payment ? (
                                <>
                                    <PaymentForm 
                                        onChange={ (value) => selectPaymentMethod(value) }
                                    />
                                    {
                                        props.allowToProceed && (
                                            <PrimaryButton 
                                                onClick={ () => paymentMethodSave() }
                                                customClass="step-address-button"
                                            >
                                                {props.onProcess ? <Spinner animation='border' /> : 'Continue'}
                                            </PrimaryButton>
                                        )
                                    }
                                    <SecondaryButton 
                                        onClick={ () => setFormState(stateEnum.shipping) }
                                        customClass="step-address-back-button"
                                    >
                                        Back
                                    </SecondaryButton>
                                </>
                            ) : ""
                        }
                        {
                            formState === stateEnum.paymentDirection ? (
                                <>
                                    <PaymentDirectionForm 
                                        onChange={ (value) => selectPaymentRecipient(value) }
                                    />
                                    {
                                        props.allowToProceed && (
                                            <PrimaryButton 
                                                onClick={ () => checkout() }
                                                customClass="step-address-button"
                                            >
                                                {props.onProcess ? <Spinner animation='border' /> : 'Continue'}
                                            </PrimaryButton>
                                        )
                                    }
                                    <SecondaryButton 
                                        onClick={ () => setFormState(stateEnum.payment) }
                                        customClass="step-address-back-button"
                                    >
                                        Back
                                    </SecondaryButton>
                                </>
                            ) : ""
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect(mapStateToProps)(CheckoutSection);