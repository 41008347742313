import React, { useEffect, useState } from 'react';

import Init from '../../../../Init/Init';
import './Values.scss';

import LoadingPage from '../../../Utilities/LoadingPage/LoadingPage';
import PrimaryButton from '../../../Utilities/PrimaryButton/PrimaryButton';

import useAnalyticsEventTracker from '../../../../GoogleAnalytics/useAnalyticsEventTracker';
import UtilityFunction from '../../../../Util/Util';
import Carousel from 'react-multi-carousel';

const { buildWaLink } = UtilityFunction;

const GaTracker = ({
    action,
    label
}) => {
    const gaEventCategory = 'Values';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    gaEventTracker(action, label);
};

const ValuesOldComponent = ({ boxPosition }) => {
    const freeShippingIcon = Init.config.icons.freeShipping;
    const highQualityIcon = Init.config.icons.highQuality;
    const variationIcon = Init.config.icons.variations;

    return (
        <div className="values" 
            style={{
                top: boxPosition
            }}
        >
            {/* 
                Each items has:
                1. Icon
                2. Title
                3. Desc
            */}
            <div className="values-box">
                <div className="values-item">
                    <img src={ freeShippingIcon } alt="" className='values-item-image' />
                    <h3 className="values-item-title">
                        PENGIRIMAN
                    </h3>
                    <p className="values-item-desc">
                        YOBOY CUP melakukan pengiriman ke seluruh Indonesia dan luar negeri.
                    </p>
                </div>
                <div className="values-item">
                    <img src={ highQualityIcon } alt="" className='values-item-image' />
                    <h3 className="values-item-title">
                        HASIL CETAKAN BERKUALITAS
                    </h3>
                    <p className="values-item-desc">
                        YOBOY CUP hanya menggunakan bahan baku kualitas terbaik yang aman dan bagus untuk Industri Food and Beverages (F&B).
                    </p>
                </div>
                <div className="values-item">
                    <img src={ variationIcon } alt="" className='values-item-image' />
                    <h3 className="values-item-title">
                        CUSTOM DESIGN & UKURAN
                    </h3>
                    <p className="values-item-desc">
                        YOBOY CUP menghadirkan layanan custom produk dengan desain dan ukuran yang kamu inginkan mulai dari 250 pcs. Yuk, custom sekarang!
                    </p>
                </div>
            </div>
        </div>
    );
}

const Values = (props) => {
    const freeShippingIcon = Init.config.icons.freeShipping;
    const highQualityIcon = Init.config.icons.highQuality;
    const variationIcon = Init.config.icons.variations;

    const [ boxPosition, setBoxPosition ] = useState();

    // Call API
    useEffect(() => {
        const headerOffsetHeight = document.getElementById('header').offsetHeight;
        setBoxPosition(headerOffsetHeight + 40);

        const resizeEvent = function(event){
            const headerOffsetHeight = document.getElementById('header').offsetHeight;
            setBoxPosition(headerOffsetHeight + 40);
        }
        window.addEventListener('resize', resizeEvent);

        return () => {
            window.removeEventListener('resize', resizeEvent);
        }
    }, []);

    /**
     * Carousels
     */
    let carouselConfig = Init.config.carousel;
    /**
     * Make sure to customize config before storing it to state 
     * if necessary.
     * 
     * E.g: 
     * let carouselConfig = Init.config.carousel
     * carouselConfig.swipeable = false?
     */

    carouselConfig.containerClass = "value-carousel-container";
    carouselConfig.itemClass = "values-item";
    carouselConfig.showDots = false;
    carouselConfig.infinite = false;
    carouselConfig.responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1400 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1400, min: 900 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 900, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
    }

    // return <ValuesOldComponent 
    //     boxPosition={ boxPosition }
    // />;
    return (
        <div className="values" 
            style={{
                top: boxPosition
            }}
        >
            <Carousel 
                swipeable={carouselConfig.swipeable}
                draggable={carouselConfig.draggable}
                showDots={carouselConfig.showDots}
                responsive={carouselConfig.responsive}
                ssr={carouselConfig.ssr} // means to render carousel on server-side.
                infinite={carouselConfig.infinite}
                autoPlay={carouselConfig.autoPlay}
                autoPlaySpeed={carouselConfig.autoPlaySpeed}
                keyBoardControl={carouselConfig.keyBoardControl}
                transitionDuration={carouselConfig.transitionDuration}
                removeArrowOnDeviceType={carouselConfig.removeArrowOnDeviceType}
                deviceType={carouselConfig.deviceType}
                containerClass={carouselConfig.containerClass}
                dotListClass={carouselConfig.dotListClass}
                itemClass={carouselConfig.itemClass}
                centerMode={carouselConfig.centerMode}>
                <>
                    <img src={ freeShippingIcon } alt="" className='values-item-image' />
                    <h3 className="values-item-title">
                        PENGIRIMAN
                    </h3>
                    <p className="values-item-desc">
                        YOBOY CUP melakukan pengiriman ke seluruh Indonesia dan luar negeri. Semua orderan yang masuk di jam 09.00-14.00 akan di proses di hari yang sama. Orderan di atas jam 14.00 akan di proses besok harinya. 
                    </p>
                    {/* <div className="values-item-text-wrapper">
                    </div> */}
                </>
                <>
                    <img src={ highQualityIcon } alt="" className='values-item-image' />
                    <h3 className="values-item-title">
                        HASIL CETAKAN BERKUALITAS
                    </h3>
                    <p className="values-item-desc">
                        YOBOY CUP hanya menggunakan bahan baku kualitas terbaik yang aman dan bagus untuk Industri Food and Beverages (F&B).
                    </p>
                </>
                <>
                    <img src={ variationIcon } alt="" className='values-item-image' />
                    <h3 className="values-item-title">
                        CUSTOM DESIGN & UKURAN
                    </h3>
                    <p className="values-item-desc">
                        YOBOY CUP menghadirkan layanan custom produk dengan desain dan ukuran yang kamu inginkan mulai dari 250 pcs. Yuk, custom sekarang!
                    </p>
                </>
            </Carousel>
        </div>
    );
}



export default Values;