import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import { isMobile } from 'react-device-detect';

// Constants
const constants = {
    transactionStatus: {
        0: 'Menunggu Pembayaran',
        1: 'Menunggu Konfirmasi',
        2: 'Sedang diproses',
        3: 'Dalam Pengiriman',
        4: 'Selesai',
        5: 'Dibatalkan',
        // 6: 'Dikomplain'
    }
};

const orderRule = {
    moq: 0,
    maxQty: 100000
};

var Init = {};

Init.constants = constants;

// Basic config
Init.config = {
    carousel: {
        swipeable: true,
        draggable: true,
        showDots: true,
        responsive: {
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 3,
              slidesToSlide: 3 // optional, default to 1.
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2,
              slidesToSlide: 2 // optional, default to 1.
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            }
        },
        ssr: false, // means to render carousel on server-side.
        infinite: true,
        autoPlay: false,
        autoPlaySpeed: 99999,
        keyBoardControl: true,
        customTransition: "all .5",
        transitionDuration: 500,
        removeArrowOnDeviceType: [
            // "tablet", 
            // "mobile", 
            // "web"
        ],
        deviceType: "web",
        partialVisible: false,
        centerMode: false,
        containerClass: "carousel-container",
        dotListClass: "custom-dot-list-style",
        itemClass: "carousel-item-padding-40-px"
    },
    customImageFilter: {
        none: [
            1, 0, 0, 0, 0,
            0, 1, 0, 0, 0,
            0, 0, 1, 0, 0,
            0, 0, 0, 1, 0,
        ],
        grayscale: [
            0.85, 0, 0, 0, 0,
            0.85, 0, 0, 0, 0,
            0.85, 0, 0, 0, 0,
            0, 0, 0, 1, 0,
        ]
    },
    imageFolder: "/assets/images/",
    linktreeFolder: "/assets/images/linktree/",
    aboutUsFolder: "/assets/images/about-us/",
    imageLogo: "/assets/logo/logo.png",
    imageLogoAlt: "/assets/logo/logo-alt.png",
    iconFolder: "/assets/icons/",
    brandsFolder: "/assets/images/brands/",
    productDummyFolder: `/assets/images/product-dummy/`,
    testimonyDummyFolder: `/assets/images/testimony-dummy/`,
    backgroundFolder: `/assets/background/`,
    headerSliderFolder: `/assets/header-slider/`,
    videoFolder: `/assets/video/`,
    login: {},
    icons: {},
    header: {},
    aboutUs: {},
    brands: {},
    background: {},
    orderInstruction: {},
    isMobile: isMobile,
    orderRule,
    allowTransaction: parseInt(process.env.REACT_APP_ALLOW_TRANSACTION)
};

// Login
Init.config.login.loginImage = Init.config.imageFolder + "login-image.png";
Init.config.login.loginVideo = Init.config.videoFolder + "login-video.mp4";

// Header
Init.config.header.firstImage = Init.config.headerSliderFolder + "1.PNG";
Init.config.header.secondImage = Init.config.headerSliderFolder + "2.PNG";
Init.config.header.thirdImage = Init.config.headerSliderFolder + "3.PNG";
Init.config.header.fourthImage = Init.config.headerSliderFolder + "4.PNG";
Init.config.header.fifthImage = Init.config.headerSliderFolder + "5.PNG";
Init.config.header.sixthImage = Init.config.headerSliderFolder + "6.PNG";
Init.config.header.seventhImage = Init.config.headerSliderFolder + "7.PNG";

// Order Instruction
Init.config.orderInstruction.illustration = Init.config.imageFolder + "illustration.png";
// Init.config.orderInstruction.cup = Init.config.imageFolder + "cup-image.png";
Init.config.orderInstruction.cup = Init.config.imageFolder + "order-instruction-asset.png";

// About Us
Init.config.aboutUs.firstImage = Init.config.aboutUsFolder + "landscape-1.png";
Init.config.aboutUs.secondImage = Init.config.aboutUsFolder + "landscape-2.png";
Init.config.aboutUs.thirdImage = Init.config.aboutUsFolder + "landscape-3.png";
Init.config.aboutUs.fourthImage = Init.config.aboutUsFolder + "landscape-4.png";

// Brands
Init.config.brands = {
    tanatap: Init.config.brandsFolder + "tanatap.png",
    harperCordon: Init.config.brandsFolder + "harper-cordon.png",
    menegaCafe: Init.config.brandsFolder + "menega-cafe.png",
    kopitagram: Init.config.brandsFolder + "kopitagram.png",
    milkLife: Init.config.brandsFolder + "milk-life.png",
    butterman: Init.config.brandsFolder + "butterman.png",
    esteh: Init.config.brandsFolder + "esteh.png",
    sevenam: Init.config.brandsFolder + "7am.png",
    susuMbokDarmi: Init.config.brandsFolder + "susu-mbok-darmi.png",
    dpakar: Init.config.brandsFolder + "d-pakar.png",
    // foyu: Init.config.brandsFolder + "foyu.png",
    hutanjati: Init.config.brandsFolder + "hutanjati.png",
    upperClift: Init.config.brandsFolder + "upper-clift.png",
    beerMart: Init.config.brandsFolder + "beer-mart.png",
    // major: Init.config.brandsFolder + "major.png",
    duacoffee: Init.config.brandsFolder + "dua-coffee.png",
    maskGroup: Init.config.brandsFolder + "mask-group.png",
    gulaliWataAme: Init.config.brandsFolder + "gulali-wata-ame.png",
    ritatkala: Init.config.brandsFolder + "ritatkala.png",
    matangDiPohon: Init.config.brandsFolder + "matang-di-pohon.png",
    mattea: Init.config.brandsFolder + "mattea.png",
    stuja: Init.config.brandsFolder + "stuja.png",
    cendolPandan: Init.config.brandsFolder + "cendol-pandan.jpg",
    doughzen: Init.config.brandsFolder + "doughzen.jpg",
    gasinc: Init.config.brandsFolder + "gasinc.jpg",
    kedaiInn: Init.config.brandsFolder + "kedai-inn.png",
    theWolf: Init.config.brandsFolder + "the-wolf.png",
    trilogi: Init.config.brandsFolder + "trilogi.png",
    truffleBelly: Init.config.brandsFolder + "truffle-belly.png",
    oatside: Init.config.brandsFolder + "oatside.jpg",
} 

// Icons
Init.config.icons.quickLook = Init.config.iconFolder + "quick-look-icon.png";
Init.config.icons.google = Init.config.iconFolder + "google-icon.svg";
Init.config.icons.facebook = Init.config.iconFolder + "facebook-icon.svg";
Init.config.icons.favicon = Init.config.iconFolder + "icon.svg";

Init.config.icons.leftCurveArrow = Init.config.iconFolder + "left-curve-arrow.svg";
Init.config.icons.rightCurveArrow = Init.config.iconFolder + "right-curve-arrow.svg";
Init.config.icons.face = Init.config.iconFolder + "face-icon.svg";

Init.config.icons.freeShipping = Init.config.iconFolder + "free-shipping.svg";
Init.config.icons.highQuality = Init.config.iconFolder + "high-quality.svg";
Init.config.icons.variations = Init.config.iconFolder + "variation.svg";

Init.config.icons.warning = Init.config.iconFolder + "warning.svg";

Init.config.icons.whatsapp = Init.config.iconFolder + "whatsapp.svg";

Init.config.icons.cup = Init.config.iconFolder + "cup.svg";
Init.config.icons.smile = Init.config.iconFolder + "smile.svg";
Init.config.icons.package = Init.config.iconFolder + "package.svg";

// Backgrounds
Init.config.background.paymentResult = Init.config.backgroundFolder + "payment-result.svg";

// Extensions
library.add(fab, fas, far);
Init.FontAwesomeIcon = FontAwesomeIcon;


export default Init;