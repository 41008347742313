import { openDB } from 'idb';

const DB_NAME = "fileDB";
const STORE_NAMES = {
    designAttachment: 'designAttachment'
}

const initDB = async (storeName) => {
    return openDB(
        DB_NAME,
        1,
        {
            upgrade(db) {
                if( !db.objectStoreNames.contains(storeName) ) {
                    db.createObjectStore(storeName, { keyPath: 'id' });
                }
            }
        }
    );
}

const addFile = async (id, file) => {
    const db = await initDB(STORE_NAMES.designAttachment);
    // await db.put(STORE_NAMES.designAttachment, { id, file });
    const tx = db.transaction(STORE_NAMES.designAttachment, 'readwrite');
    const store = tx.objectStore(STORE_NAMES.designAttachment);
    store.put({ id, file });
}

const getFile = async (id) => {
    const db = await initDB(STORE_NAMES.designAttachment);
    const dbFile = await db.get(STORE_NAMES.designAttachment, id);

    if( dbFile !== undefined ) return dbFile.file;
    return null;
}

const deleteFile = async (id) => {
    console.log("Deleting file!");

    const db = await initDB(STORE_NAMES.designAttachment);
    await db.delete(STORE_NAMES.designAttachment, id);
    // const tx = db.transaction(STORE_NAMES.designAttachment, 'readwrite');
    // const store = tx.objectStore(STORE_NAMES.designAttachment);
    // store.delete(id);
}

export default initDB;
export const fileManager = {
    addFile,
    getFile,
    deleteFile
}
