import React, { useEffect, useState } from 'react';

import Init from '../../../../Init/Init';
import './Brands.scss';

import useAnalyticsEventTracker from '../../../../GoogleAnalytics/useAnalyticsEventTracker';

const GaTracker = ({
    action,
    label
}) => {
    const gaEventCategory = 'Brands';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    gaEventTracker(action, label);
};

const Brands = (props) => {
    const brands = Object.values(Init.config.brands);
    const leftCurveArrow = Init.config.icons.leftCurveArrow;
    const rightCurveArrow = Init.config.icons.rightCurveArrow;
    const face = Init.config.icons.face;

    // Call API
    useEffect(() => {
        
    }, []);

    const order = () => {

    }

    return (
        <div id="brands" className="brands">
            <div className="brands-border-top">
                <div className="brands-top"></div>
                <div className="brands-top"></div>
                <div className="brands-top"></div>
                <div className="brands-top"></div>
                <div className="brands-top"></div>
                <div className="brands-top"></div>
                <div className="brands-top"></div>
                <div className="brands-top"></div>
                <div className="brands-top"></div>
                <div className="brands-top"></div>
            </div>
            {/* 
                1. Header (Title, Desc)
                2. Logos
                3. Many More
            */}
            <div className="brands-header">
                <div className="brands-header-icon">
                    <img src={ leftCurveArrow } />
                </div>
                <div className="brands-header-content">
                    <h2 className="brand-title">
                        Brand Yang Sudah Kerjasama
                    </h2>
                    <div className="brand-desc">
                        Mulai dari UMKM hingga pelaku usaha kedai kopi ternama telah menggunakan produk YOBOY CUP
                    </div>
                </div>
                <div className="brands-header-icon">
                    <img src={ rightCurveArrow } />
                </div>
            </div>
            <div className="brands-list">
                { 
                    brands.map( (item, index) => {
                        return (
                            <div key={index} className="brand-item">
                                <img src={item} alt={item} />
                            </div>
                        )
                    })
                }
            </div>
            <div className="brands-see-more">
                <img src={ face } /> ...and many more!
            </div>
        </div>
    );
}



export default Brands;