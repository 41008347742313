import shoppingCartAPI from '../../Data/ShoppingCart';
import * as actionTypes from './shopping-types';

import Init from '../../Init/Init';

import Cookie from 'universal-cookie';
import { fileManager } from '../../Init/indexedDB';

const cookies = new Cookie();
const { config } = Init;

const addToCart = (ownerCode, productItem, transactionNotes) => {
    return dispatch => {
        // let authToken = cookies.get('authToken');
        let authToken = "";

        if(authToken !== undefined && authToken !== null && authToken !== '') {
            shoppingCartAPI.call.addToCart(authToken, productItem).then( (response) => {
                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    ownerCode = response.data.customer_code;
                    dispatch(addToCartSuccess(ownerCode, productItem, transactionNotes));
                }
            } );

        } else {
            dispatch(addToCartSuccess(ownerCode, productItem, transactionNotes));
        }
    }
}

const addToCartSuccess = (ownerCode, productItem, transactionNotes) => {
    return {
        type: actionTypes.ADD_TO_CART,
        payload: {
            ownerCode,
            productItem,
            transactionNotes
        }
    }
}

const removeFromCart = (ownerCode, productId) => {
    return dispatch => {
        // let authToken = cookies.get('authToken');
        let authToken = "";

        if(authToken !== undefined && authToken !== null && authToken !== '') {
            shoppingCartAPI.call.removeCartProduct(authToken, productId).then( (response) => {
                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    ownerCode = response.data.customer_code;
                    dispatch(removeFromCartSuccess(ownerCode, productId));
                }
            } );
        } else {
            dispatch(removeFromCartSuccess(ownerCode, productId));
        }
    }
}

const removeFromCartSuccess = (ownerCode, productId) => {
    return {
        type: actionTypes.REMOVE_FROM_CART,
        payload: {
            ownerCode,
            productId: productId
        }
    };
}

const adjustQty = (ownerCode, productId, value) => {
    return dispatch => {
        if(value < config.orderRule.moq) {
            dispatch(removeFromCart(ownerCode, productId));
        } else {
            dispatch(adjustQtySuccess(ownerCode, productId, value));
        }
    }    
}

const adjustQtySuccess = (ownerCode, productId, value) => {
    return {
        type: actionTypes.ADJUST_QTY,
        payload: {
            ownerCode,
            productId: productId,
            qty: value
        }
    }
}

const loadCart = (ownerCode = 'guest') => {
    return dispatch => {
        let cartData;
        // let authToken = cookies.get('authToken');
        let authToken = "";
        let loadFromLocal = false;

        if(authToken !== undefined && authToken !== null && authToken !== '') {
            shoppingCartAPI.call.loadUserCart(authToken).then( (response) => {
                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    cartData = response.data.cart;
                    ownerCode = response.data.customer_code;

                    console.log(`Response API CALL: ${JSON.stringify(response)}`);
                    
                    dispatch(loadCartSuccess(ownerCode, cartData));
                } else {
                    loadFromLocal = true;
                }
            } );
        } else {
            loadFromLocal = true;
        }

        if(loadFromLocal) {
            cartData = shoppingCartAPI.controller.get(ownerCode);
            dispatch(loadCartSuccess(ownerCode, cartData));
        }
    }
}

const loadCartSuccess = (ownerCode = 'guest', cartData) => {
    return {
        type: actionTypes.LOAD_CART,
        payload: {
            ownerCode,
            cartData
        }
    }
} 

const clearCart = (ownerCode = 'guest') => {
    return {
        type: actionTypes.CLEAR_CART,
        payload: {
            ownerCode
        }
    }
}

const loadDesignAttachment = async () => {
    const attachment = await fileManager.getFile(1);

    return dispatch => {
        dispatch(passDesignAttachmentCommand({
            type: actionTypes.LOAD_DESIGN_ATTACHMENT,
            payload: {
                attachment
            }
        }));
    }
}

const saveDesignAttachment = async (file) => {
    await fileManager.addFile(1, file);

    return dispatch => {
        dispatch(passDesignAttachmentCommand({
            type: actionTypes.SAVE_DESIGN_ATTACHMENT,
            payload: {
                attachment: file
            }
        }))
    };
}

const clearDesignAttachment = () => {
    fileManager.deleteFile(1);

    return {
        type: actionTypes.CLEAR_DESIGN_ATTACHMENT,
        payload: {}
    }
    // return dispatch => {
    //     dispatch(passDesignAttachmentCommand({
    //         type: actionTypes.CLEAR_DESIGN_ATTACHMENT,
    //         payload: {}
    //     }))
    // };
}

const passDesignAttachmentCommand = (obj) => {
    return obj;
}

const shoppingCartActions = {
    addToCart,
    removeFromCart,
    adjustQty,
    loadCart,
    clearCart,

    loadDesignAttachment,
    saveDesignAttachment,
    clearDesignAttachment
}

export default shoppingCartActions;