import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import transactionAPI from '../../../../Data/Transaction';

import Init from '../../../../Init/Init';
import UtilityFunction from '../../../../Util/Util';
import ContactInfo from '../../../../Data/Contact';

import './OrderDetail.scss';
import PrimaryButton from '../../../Utilities/PrimaryButton/PrimaryButton';
import LoadingPage from '../../../Utilities/LoadingPage/LoadingPage';

const { imageFolder } = Init.config;
const { transactionStatus } = Init.constants;
const { getFormattedDatetime, numberFormatting } = UtilityFunction;
const { bankAccount } = ContactInfo();

const OrderDetail = (props) => {
    const [ data, setData ] = useState({});

    const defaultImage = `${imageFolder}/placeholder.jpeg`;
    const navigate = useNavigate();

    useEffect( () => {
        if(props.transactionCode !== '') {
            ( async () => {
                const response = await transactionAPI.getOrderDetail(props.transactionCode);
                
                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    setData({
                        ...response.data
                    });
                    props.clickPayNow(response.data.payment_link);
                }
            } )();
        }
    }, [props.transactionCode]);

    const constructRecipientInfo = () => {
        return `${ data.recipient_account?.bank_code } - ${ data.recipient_account?.account_no } A/N ${ data.recipient_account?.account_name }`;
    }

    const payNow = () => {
        // Open payment confirmation modal
        props.clickPayNow(data.payment_link);
    }

    return data ? (
        <div className='order-detail'>
            {/* 
                Three parts:
                1. Header (Title, Description)
                2. Payment Info
                3. Payment Instruction
            */}
            <div className="order-detail-header">
                <h1 className='order-detail-header-title'>
                    Place Order
                </h1>
                {
                    data.payment_link ? '' : (
                        <span className="order-detail-header-desc">
                            Mohon lakukan pembayaran ke rekening { constructRecipientInfo() }
                        </span>
                    )
                }
            </div>
            <div className="order-detail-payment-info">
                <hr />
                <div className="payment-info-item">
                    <span className="label">
                        Kode Transaksi
                    </span>
                    <span className="value">
                        { data.transaction_code }  
                    </span>
                </div>
                <hr />
                <div className="payment-info-item">
                    <span className="label">
                        Subtotal
                    </span>
                    <span className="value">
                        { numberFormatting(data.total_price) }  
                    </span>
                </div>
                <hr />
                <div className="payment-info-item">
                    <span className="label">
                        PPN (11%)
                    </span>
                    <span className="value">
                        { numberFormatting(data.tax_fee) }  
                    </span>
                </div>
                <hr />
                <div className="payment-info-item">
                    <span className="label">
                        Ongkos Kirim
                    </span>
                    <span className={`value ${ data.shipping_fee > 0  ? "" : "free"}`}>
                        { data.shipping_fee > 0  ? numberFormatting(data.shipping_fee) : "FREE" }
                    </span>
                </div>
                <hr />
                <div className="payment-info-item total">
                    <span className="label">
                        Total
                    </span>
                    <span className="value">
                        { numberFormatting(data.total) }  
                    </span>
                </div>
            </div>
            <div className="order-detail-footer">
                {
                    data.payment_link ? '' : (
                        <span className="footer-instruction">
                            Jika Anda <b>SUDAH</b> melakukan pembayaran silakan upload bukti transfer Anda untuk di verifikasi lebih lanjut oleh admin kami.
                        </span>
                    )
                }
                <PrimaryButton
                    customClass={"btn-proceed"}
                    size={"md"}
                    onClick={() => payNow()}
                >
                    Continue
                </PrimaryButton>
            </div>
        </div>
    ) : (
        <LoadingPage />
    );
}

export default OrderDetail;