import React, { Component, createRef, useState, useEffect } from 'react';
import './CheckoutPage.scss';

import NavigationContext from '../../Context/NavigationContext';

import AddressSection from '../../Component/PagesComponent/AddressSection/AddressSection';

import EmptyCart from '../../Component/Utilities/EmptyCart/EmptyCart';
import CheckoutSection from '../../Component/Utilities/CheckoutSection/CheckoutSection';
import CustomToast from '../../Component/Utilities/Toast/Toast';

import transactionAPI from '../../Data/Transaction';
import shippingAPI from '../../Data/Shipping';
import UtilityFunction from '../../Util/Util';

import { connect } from 'react-redux';
import { getAuthenticatedUser } from '../../Redux/Auth/auth-action';
import shoppingCartActions from '../../Redux/Shopping/shopping-action';

import useAnalyticsEventTracker from '../../GoogleAnalytics/useAnalyticsEventTracker';

const GaTracker = ({
    action,
    label
}) => {
    const gaEventCategory = 'Checkout';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    gaEventTracker(action, label);
};

const { clearCart, clearDesignAttachment } = shoppingCartActions;
const { objectIsEmpty } = UtilityFunction;

const mapStateToProps = (state) => {
    return {
        identity: state.auth.identity,
        transactionNotes: state.shop.transactionNotes
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        clearCart: (ownerCode) => dispatch(clearCart(ownerCode)),
        clearAttachment: () => dispatch(clearDesignAttachment()),
        getAuthenticatedUser: () => dispatch(getAuthenticatedUser())
    }
}

const ToastHandler = (props) => {
    const [ toastContent, setToastContent ] = useState({
        title: '',
        content: ``,
        status: null,
        show: false
    });

    const closeToast = () => {
        props.onClose();
    };

    useEffect(() => {
        setToastContent({
            ...toastContent,
            title: props.title,
            content: props.content,
            status: props.status,
            show: props.show
        });
    }, [props]);

    return (
        <CustomToast show={toastContent.show} 
                    onClose={closeToast} 
                    title={toastContent.title} 
                    status={toastContent.status}
                    customClass="checkout-warning-toast"
        >
            <div className="content">
                { toastContent.content }
            </div>
        </CustomToast>
    );
}
class CheckoutPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cartData: [],
            isLoading: true,
            addressData: {
                name: {
                    label: "",
                    ref: createRef(''),
                    value: '',
                    width: 12, // x < 12
                    type: "text",
                    required: true,
                    placeholder: 'Nama'
                },
                company_name: {
                    label: "",
                    ref: createRef(''),
                    value: '',
                    width: 12, // x < 12
                    type: "text",
                    placeholder: 'Nama Perusahaan'
                },
                email: {
                    label: "",
                    ref: createRef(''),
                    value: '',
                    width: 12, // x < 12
                    type: "text",
                    required: true,
                    placeholder: 'Email'
                },
                phone: {
                    label: "",
                    ref: createRef(''),
                    value: '',
                    width: 12, // x < 12
                    type: "text",
                    required: true,
                    placeholder: 'No. Telp',
                    prefix: '+62'
                },
                province: {
                    label: "",
                    ref: createRef(0),
                    value: 0,
                    width: 6, // x < 12
                    type: "select",
                    required: true,
                    options: [
                        {'value': 0, 'label': 'Pilih Provinsi'},
                        {'value': 1, 'label': 'DKI Jakarta'},
                        {'value': 2, 'label': 'Bekasi'},
                        {'value': 3, 'label': 'Depok'},
                        {'value': 4, 'label': 'Bandung'}
                    ],
                },
                regency: {
                    label: "",
                    ref: createRef(0),
                    value: 0,
                    width: 6, // x < 12
                    type: "select",
                    required: true,
                    options: [
                        {'value': 0, 'label': 'Pilih Kota/Kabupaten'},
                        {'value': 1, 'label': 'Jakarta Barat'},
                        {'value': 2, 'label': 'Jakarta Utara'},
                        {'value': 3, 'label': 'Jakarta Timur'},
                        {'value': 4, 'label': 'Jakarta Pusat'},
                        {'value': 5, 'label': 'Jakarta Selatan'}
                    ]
                },
                district: {
                    label: "",
                    ref: createRef(0),
                    value: 0,
                    width: 6, // x < 12
                    type: "select",
                    required: true,
                    options: [
                        {'value': 0, 'label': 'Pilih Kecamatan'},
                        {'value': 1, 'label': 'Kalideres'},
                        {'value': 2, 'label': 'Tanjung Duren'},
                        {'value': 3, 'label': 'Pluit'},
                        {'value': 4, 'label': 'Sunter'}
                    ]
                },
                village: {
                    label: "",
                    ref: createRef(0),
                    value: 0,
                    width: 6, // x < 12
                    type: "select",
                    required: true,
                    options: [
                        {'value': 0, 'label': 'Pilih Kelurahan'},
                        {'value': 1, 'label': 'Pegadungan'},
                        {'value': 2, 'label': 'Cengkareng'},
                        {'value': 3, 'label': 'Penjaringan'},
                        {'value': 4, 'label': 'Pluit'}
                    ]
                },
                postal_code: {
                    label: "",
                    ref: createRef(''),
                    value: '',
                    width: 6, // x < 12
                    type: "text",
                    required: true,
                    placeholder: 'Kode Pos'
                },
                street_address: {
                    label: "",
                    ref: createRef(''),
                    value: '',
                    width: 12, // x < 12
                    type: "text",
                    required: true,
                    placeholder: 'Alamat'
                },
            },
            chosenAddress: createRef(),
            toastContent: {
                title: '',
                content: '',
                status: null,
                show: false
            },
            deliveryService: {
                show: false,
                data: [],
                destinationId: '',
                totalQty: 0
            },
            usePaymentGateway: 0
        }
    }

    static contextType = NavigationContext;

    componentDidMount() {
        const thisState = this.state;

        const context = this.context;
        const { location } = context;
        const { state } = location;

        if(state !== null) {
            let { cartData } = state;

            if(cartData !== null) {
                thisState.cartData = cartData;
            }
        }
        
        thisState.isLoading = false;

        if(!objectIsEmpty(this.props.identity)) {
            // thisState.addressData
            /**
             * thisState.addressData.name.value = this.props.identity.address[0].receiver_fullname
             * thisState.addressData.email.value = this.props.identity.address[0].receiver_email
             * thisState.addressData.province = this.props.identity.address[0].receiver_phoness
             */
            thisState.isLogin = true;
            thisState.addressList = this.props.identity.address;
        }

        this.setState(thisState);

        window.addEventListener('resize', function(event){});
        
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     return this.props.identity
    // }

    componentDidUpdate() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    
    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };

        window.removeEventListener('resize', () => {});
    }

    getTotalPrice() {
        return this.state.cartData.reduce( (total, item) => {
            let usePrice = item.hasOwnProperty('product_discount_price') && item.product_discount_price > 0 ? item.product_discount_price : item.product_price;
            return total + (usePrice * item.quantity);
        }, 0);
    }

    generateRequestData() {
        let state = this.state;
        let requestData = {};

        if(state.chosenAddress.current === 0) {
            if(state.addressData.name.ref.current !== null) {
                for(let key in state.addressData) {
                    let item = state.addressData[key];
                    let value = item.ref.current.value;

                    if(item.hasOwnProperty('prefix') && item.prefix !== '') {
                        value = item.prefix + value;
                    }
        
                    requestData[key] = value;
                }

                for(let key in state.addressData) {
                    let item = state.addressData[key];
                    let value = item.ref.current.value;

                    state.addressData[key].value = value;

                }
            }
        } else {
            let addressList = this.props.identity.address;
            let addressData = addressList.find(item => item.id === state.chosenAddress.current);
            requestData = {
                name: addressData.receiver_name,
                phone: addressData.phone_number,
                email: addressData.receiver_email,
                province: addressData.province_id,
                regency: addressData.regency_id,
                district: addressData.district_id,
                village: addressData.village_id,
                postal_code: addressData.postal_code,
                street_address: addressData.street_address,
            }
        }

        return requestData;
    }

    failedToast(requestData) {
        let state = this.state;
        let cartData = state.cartData;

        if(objectIsEmpty(requestData)) {
            state.toastContent.title = 'Checkout Failed';
            state.toastContent.content = 'Address Information is required!'
            state.toastContent.status = 0;
            state.toastContent.show = true;
        } else if(cartData.length === 0) {
            state.toastContent.title = 'Checkout Failed';
            state.toastContent.content = 'Your cart is empty'
            state.toastContent.status = 0;
            state.toastContent.show = true;
        }

        this.setState({
            ...state
        });
    }

    checkout(courierCode, courierServiceCode) {
        this.setState({
            ...state,
            usePaymentGateway,
        });

        let state = this.state;
        let requestData = this.generateRequestData();
        let cartData = state.cartData;
        let usePaymentGateway = state.usePaymentGateway;
        const context = this.context;
        const { navigate } = context;

        this.setState({
            ...state,
            addressData: {}
        });

        requestData.courier_code = courierCode;
        requestData.courier_service_code = courierServiceCode;

        if(!objectIsEmpty(requestData) && cartData.length > 0) {
            /**
             * 1. Call API to create transaction
             * 2. Receive Response
             * 3. Failed, Check form request
             * 4. Success, redirect to transaction detail page (Get transaction code to check)
             */
            this.setState({
                ...state,
                isLoading: true
            });

            (async() => {
                const response = await transactionAPI.order({
                    transactionData: requestData,
                    transactionItems: cartData,
                    usePaymentGateway,
                    transactionNotes: this.props.transactionNotes
                });

                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    // console.log("Checkout success!");

                    this.props.clearCart();
                    this.props.clearAttachment();
                    
                    if(usePaymentGateway && response.data.hasOwnProperty('paymentLink') && response.data.paymentLink !== '') {
                        window.open(response.data.paymentLink, "_blank");
                    }

                    GaTracker({
                        action: `Create Transaction`,
                        label: `Checkout ${ usePaymentGateway ? 'with Xendit' : 'manually' }`
                    });

                    state.toastContent.title = 'Success';
                    state.toastContent.content = response.message;
                    state.toastContent.status = 1;
                    state.toastContent.show = true;
                    
                    navigate(`/order/${response.data.transactionCode}`, {
                        replace: true,
                        state: {
                            success: 1
                        }
                    });

                } else {
                    // Display error if possible;
                    for(let index in response.data) {
                        let item = response.data[index];
                        let { 
                            // actual, 
                            field, 
                            message, 
                            // type 
                        } = item;

                        state.addressData[field].hasError = true;
                        state.addressData[field].errorMessage = message;
                    }

                    state.toastContent.title = 'Failed';
                    state.toastContent.content = response.message;
                    state.toastContent.status = 0;
                    state.toastContent.show = true;
                    console.log(state);
                }

                this.setState({
                    ...state,
                    toastContent: {
                        ...state.toastContent
                    },
                    isLoading: false
                });
            })();
        } else {
            this.failedToast(requestData);
        }
    }

    closeToast() {
        let state = this.state;
        state.toastContent.show = false;
        this.setState({
            ...state
        });
    }

    render() {
        return (
            <div className="checkout-page">
                <ToastHandler 
                    title={this.state.toastContent.title}
                    content={this.state.toastContent.content}
                    status={this.state.toastContent.status}
                    show={this.state.toastContent.show} 
                    onClose={() => this.closeToast()} />
                {
                    this.state.cartData.length > 0 ? (
                        // {/*
                        //     Two Big Parts:
                        //     1. Checkout Form
                        //     2. Checkout Section
                        // */}
                        <>
                            <CheckoutSection checkout={ (usePaymentGateway) => this.checkout(usePaymentGateway) } totalPrice={ this.getTotalPrice() } />
                            <AddressSection 
                                useTitle={true} 
                                addressData={this.state.addressData} 
                                chosenAddress={this.state.chosenAddress} 
                                className="checkout-address-list"
                            />
                        </>
                    ) : (
                        <EmptyCart isLoading={this.state.isLoading} />
                    )
                }
            </div>
        ) 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);