import React, { useEffect, useState } from 'react';

import Init from '../../../../Init/Init';
import './Testimony.scss';

import LoadingPage from '../../../Utilities/LoadingPage/LoadingPage';
import PrimaryButton from '../../../Utilities/PrimaryButton/PrimaryButton';

import useAnalyticsEventTracker from '../../../../GoogleAnalytics/useAnalyticsEventTracker';
import testimonyAPI from '../../../../Data/Testimony';

const GaTracker = ({
    action,
    label
}) => {
    const gaEventCategory = 'Testimony';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    gaEventTracker(action, label);
};

const Testimony = (props) => {
    const smileIcon = Init.config.icons.smile;

    const [testimony, setTestimony] = useState([]);

    // Call API
    useEffect(() => {
        ( async () => {
            const response = await testimonyAPI.testimonyList();

            if( response.hasOwnProperty('errorCode') && response.errorCode === 0 ) {
                setTestimony(response.data);
            } else {
                setTestimony([]);
            }
        } )();
    }, []);

    return (
        <div className="testimony">
            {/* 
                1. Header (Title, Desc, Icon)
                2. Catalog
            */}
            <div className="testimony-header">
                <div className="testimony-header-content">
                    <h2 className="testimony-title">
                        Testimoni Pelanggan
                    </h2>
                    <p className="testimony-desc">
                        Pendapat mereka tentang kualitas & pelayanan di Yoboy Cup.
                    </p>
                </div>
                <div className="testimony-header-icon">
                    <img src={ smileIcon } alt="" />
                </div>
            </div>
            <div className="testimony-list">
                {
                    testimony && testimony.map( (item, index) => {
                        return (
                            <div key={index} className="testimony-item">
                                <img src={ item.testimonyImage } className='testimony-item-image' />
                                <div className="testimony-item-desc">
                                    { item.testimony }
                                </div>
                                {/* <div className="testimony-item-name">
                                    { item.testimonySender }
                                </div> */}
                            </div>
                        );
                    } )
                }
            </div>
        </div>
    );
}



export default Testimony;